.cyrcle {
  width: 22px;
  height: 22px;
  background: rgb(255, 0, 0);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cyrcle span {
  color: white;
}
